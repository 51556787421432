
ion-button {
  --background: white;
  --border-radius: 0;

  --box-shadow: 0 0px 0px 0 rgb(0, 0, 0, 0.25);

  --padding-top: 5px;
  --padding-bottom: 5px;
}

ion-list.no-last-border {
  :last-child {
    --border-style: none !important;
  }
}

